import React, { useState } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import * as styles from './index.module.less'

export default ({ data, arrowIcon, arrowActive }) => {
  const [showContent, setShowContent] = useState(false)
  const { issue_title, desc_info } = data
  const handleTaggle = () => {
    setShowContent(!showContent)
  }

  return (
    <div className="mb-6 lg:mb-8" style={{ borderBottom: '1px solid #E1E8F1', cursor: 'pointer' }}>
      <p
        onClick={handleTaggle}
        className={classnames('hidden lg:flex font-normal pb-8 justify-between items-center')}
        style={{ color: '#1F2D3D', fontSize: '24px' }}
      >
        {issue_title}
        <img
          className={showContent ? styles.showIconActive : styles.showIcon}
          src={showContent ? arrowActive : arrowIcon}
          alt="operation_icon"
        />
      </p>

      <p
        onClick={handleTaggle}
        className={classnames('flex font-normal pb-8 justify-between items-center lg:hidden')}
        style={{ color: '#1F2D3D', fontSize: '18px' }}
      >
        {issue_title}
        <img
          className={showContent ? styles.showIconActive : styles.showIcon}
          src={showContent ? arrowActive : arrowIcon}
          alt="operation_icon"
        />
      </p>

      <ul className={classnames(styles.issueContentList, showContent ? styles.show : styles.hide)}>
        {desc_info?.length > 0
          ? desc_info.map((item) => {
              return (
                <li className="text-sd-font-p leading-[28px] py-4 px-0 lg:py-5px px-0" style={{ fontSize: '14px' }}>
                  {item.content}
                  {item?.link && (
                    <Link className="break-words text-primary" target="_blank" to={item.link}>
                      {item.link}
                    </Link>
                  )}
                </li>
              )
            })
          : null}
      </ul>
    </div>
  )
}
