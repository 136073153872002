/*
 * @Author: liyu
 * @Date: 2022-06-07 16:05:40
 * @LastEditTime: 2022-06-08 15:27:49
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/trust/faq/index.tsx
 */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { TrustCenterState } from '../center/index.en'
import IssuesCard from 'components/Trust/Card/IssuesCard'
import Layout from 'layouts/en'
import * as styles from './index.module.less'

const Faq: React.FC = (props) => {
  const { trustCenterEnYaml } = useStaticQuery(
    graphql`
      query {
        trustCenterEnYaml {
          faq {
            title
            description
            key
            satitle
          }
          banner_bg_pc
          banner_bg_mb
          faq_banner_info {
            content
            title
          }
          faq_tab_list {
            is_active
            link
            name
          }
          bottom_card_title
          bottom_card_desc
          issues_first_info {
            arrow_icon
            arrow_active
          }
          issues_list {
            issue_title
            desc_info {
              content
            }
          }
        }
      }
    `,
  )

  const { faq, faq_tab_list, issues_first_info, issues_list, faq_banner_info } = trustCenterEnYaml

  return (
    <Layout {...props}>
      <TrustCenterState trustCenterEnYaml={trustCenterEnYaml} TDK={faq} bannerInfo={faq_banner_info} tabList={faq_tab_list}>
        <div className={styles.issuesContainer}>
          <ul className="py-12 px-4 lg:py-24 px-0">
            {issues_list?.length > 0
              ? issues_list.map((item) => {
                  return (
                    <li className="w-11/12 my-0 mx-auto lg: max-w-[1200px]" key={item.title}>
                      <IssuesCard
                        data={item}
                        arrowIcon={issues_first_info.arrow_icon}
                        arrowActive={issues_first_info.arrow_active}
                      />
                    </li>
                  )
                })
              : null}
          </ul>
        </div>
      </TrustCenterState>
    </Layout>
  )
}

export default Faq
